<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    let token = sessionStorage.getItem('token');
    if (token) {
      this.$router.push('home');
    }
  },
};
</script>
