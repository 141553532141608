<template>
  <div class="chart-2">
    <div class="common-title title"><span>就业者户籍区域分布</span></div>
    <div class="common-content content">
      <div id="chart2"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'Chart2',
  props: ['province'],
  data() {
    return {
      myChart: undefined,
    };
  },
  watch: {
    province: function (n) {
      if (n.length) {
        this.setData(n);
      }
    },
  },
  methods: {
    setData(data) {
      let option = {
        color: [
          '#6dc089',
          '#468fec',
          '#a2becd',
          '#e69c5a',
          '#b59ecc',
          '#654dd5',
        ],
        backgroundColor: 'transparent',
        legend: {
          bottom: '10%',
          left: 'center',
          selectedMode: false,
          itemWidth: 10,
          itemHeight: 10,
          icon: 'rect',
          padding: [0, 50],
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            width: '378px',
            height: '320px',
            radius: [0, 100],
            center: ['50%', '40%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 4,
            },
            label: {
              formatter: '{c}人',
              color: 'rgba(255, 255, 255, 0.3)',
              alignTo: 'labelLine',
              overflow: 'none',
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)',
              },
              smooth: 0.2,
              length: 5,
              length2: 5,
            },
            data,
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('chart2'), 'dark');
  },
};
</script>

<style lang="scss">
.chart-2 {
  width: 378px;
  margin-bottom: 26px;

  .title {
    span {
      &::after {
        width: 110px;
      }
    }
  }

  .content div {
    width: 378px;
    height: 320px;
  }
}
</style>
