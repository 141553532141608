// import Vue from 'vue';
import axios from 'axios';

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const { success, rtnCode } = res.data;
    if (rtnCode === -9999) {
      sessionStorage.clear();
      window.location.href = '/';
    }
    if (success) {
      return res.data;
    } else {
      // return Promise.reject(res.data);
      return res.data;
    }
  },
  (error) => {
    console.log(error);
    if (String(error).includes('timeout')) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
