<template>
  <div class="chart-3">
    <div class="common-title title"><span>近半年服务行业人次TOP3</span></div>
    <div class="common-content content">
      <div id="chart3"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'Chart3',
  props: ['month', 'countTop3'],
  data() {
    return {
      myChart: undefined,
    };
  },
  watch: {
    countTop3: function (n) {
      if (n.length) {
        this.setData(n);
      }
    },
  },
  methods: {
    setData(data) {
      let arr = [];
      for (const item of data) {
        arr.push({
          ...item,
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series',
          },
          barWidth: 20,
        });
      }
      let option = {
        color: ['#29F1FA', '#1B7EF2', '#F27C1B'],
        backgroundColor: 'transparent',
        legend: {
          top: '10%',
          left: 'center',
          selectedMode: false,
          itemWidth: 19,
          itemHeight: 3,
          icon: 'rect',
          padding: [0, 50],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.month,
            splitLine: { show: false },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(27, 126, 242, 0.5)',
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        series: arr,
      };

      this.myChart.setOption(option);
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('chart3'), 'dark');
  },
};
</script>

<style lang="scss">
.chart-3 {
  width: 378px;
  margin-bottom: 26px;

  .title {
    span {
      &::after {
        width: 57px;
      }
    }
  }

  .content div {
    width: 378px;
    height: 360px;
  }
}
</style>
