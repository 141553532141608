<template>
  <div class="box-1">
    <div class="title"><span>就业者男女比例</span></div>
    <div class="common-content content">
      <div class="item">
        <dl>
          <dt>就业者男性数量</dt>
          <dd>
            <b>{{ gender['男'] }}</b
            ><span>个</span>
          </dd>
        </dl>
        <div class="bar">
          <div class="outer">
            <div class="inner" :style="{ width: male + '%' }"></div>
          </div>
        </div>
      </div>
      <div class="item">
        <dl>
          <dt>就业者女性数量</dt>
          <dd>
            <b>{{ gender['女'] }}</b
            ><span>个</span>
          </dd>
        </dl>
        <div class="bar">
          <div class="outer">
            <div class="inner inner-2" :style="{ width: female + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
  name: 'Box1',
  props: ['gender'],
  data() {
    return {};
  },
  computed: {
    total() {
      return NP.plus(this.gender['男'], this.gender['女']);
    },
    male() {
      return NP.minus(100, this.female);
    },
    female() {
      return NP.round(
        NP.times(NP.divide(this.gender['女'], this.total), 100),
        0
      );
    },
  },
  created() {},
};
</script>

<style lang="scss" scope>
.box-1 {
  width: 378px;
  margin-bottom: 26px;

  .title {
    position: relative;
    height: 50px;
    line-height: 50px;
    background: rgba(#1b7ef2, 0.14);
    padding-left: 40px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: ' ';
      display: block;
      width: 4px;
      height: 50px;
      background: #1b7ef2;
      border-right: 3px solid #000001;
    }

    span {
      &::before {
        position: absolute;
        top: 19px;
        left: 13px;
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent transparent #29f1fa;
        border-width: 7px;
      }
    }
  }

  .content {
    height: 160px;
    padding: 0 50px 0 40px;

    .item {
      padding-top: 10px;

      & + .item {
        padding-top: 20px;
      }

      dl {
        display: flex;
        justify-content: space-between;
        margin: 0;

        dd {
          span {
            font-size: 16px;
          }
        }
      }

      .bar {
        margin-top: 12px;

        .outer {
          height: 8px;
          background: rgba(#707070, 0.23);

          .inner {
            position: relative;
            width: 0;
            height: 8px;
            background: linear-gradient(
              259deg,
              #1b7ef2 0%,
              rgba(#1b7ef2, 0) 100%
            );
            transition: width 1s ease;

            &::after {
              position: absolute;
              top: -9px;
              right: -13px;
              content: ' ';
              display: block;
              width: 26px;
              height: 26px;
              background: url('../assets/bar_cap.png') no-repeat 0 0 / contain;
            }
          }

          .inner-2 {
            background: linear-gradient(
              259deg,
              #29f1fa 0%,
              rgba(#1b7ef2, 0) 100%
            );

            &::after {
              background: url('../assets/bar_cap2.png') no-repeat 0 0 / contain;
            }
          }
        }
      }
    }
  }
}
</style>
