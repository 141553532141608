<template>
  <div class="top">
    <div class="item">
      <i class="icon-1"></i>
      <span>产业园数量</span>
      <span>
        <b>
          <countTo
            :startVal="0"
            :endVal="top.taxCount"
            :duration="3000"
          ></countTo>
        </b>
        <em>个</em>
      </span>
    </div>
    <div class="item">
      <i class="icon-2"></i>
      <span>企业数量</span>
      <span>
        <b>
          <countTo
            :startVal="0"
            :endVal="top.entCount"
            :duration="3000"
          ></countTo>
        </b>
        <em>家</em>
      </span>
    </div>
    <div class="item">
      <i class="icon-3"></i>
      <span>就业者数量</span>
      <span>
        <b>
          <countTo
            :startVal="0"
            :endVal="top.userCount"
            :duration="3000"
          ></countTo>
        </b>
        <em>万家</em>
      </span>
    </div>
    <div class="item">
      <i class="icon-4"></i>
      <span>累计产量</span>
      <span>
        <b>
          <countTo
            :startVal="0"
            :endVal="top.sumMoney"
            :duration="3000"
          ></countTo>
        </b>
        <em>亿</em>
      </span>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';

export default {
  name: 'Top',
  props: ['top'],
  components: { countTo },
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="scss">
.top {
  display: flex;
  justify-content: center;

  .item {
    width: 238px;

    i {
      float: left;
      display: block;
      width: 72px;
      height: 72px;
      margin-top: 10px;

      @for $i from 1 through 4 {
        &.icon-#{$i} {
          background: url('../assets/top_icon_#{$i}.png')
            no-repeat
            0
            0 /
            contain;
        }
      }
    }

    > span {
      display: block;
      margin-left: 88px;
      color: rgba(#fff, 0.64);

      & + span {
        display: flex;
        align-items: flex-end;

        b {
          height: 50px;
          line-height: 50px;
          margin-right: 8px;
          color: rgba(#fff, 0.87);
          font-size: 32px;
        }

        em {
          height: 40px;
          margin-top: 10px;
          font-style: normal;
        }
      }
    }
  }
}
</style>
