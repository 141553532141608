const coords = {
  安徽省: [117.216005, 31.859252],
  北京: [116.405285, 39.904989],
  福建省: [117.984943, 26.050118],
  贵州省: [106.734996, 26.902826],
  广东省: [113.394818, 23.408004],
  广西壮族自治区: [108.924274, 23.552255],
  甘肃省: [102.457625, 38.103267],
  河南省: [113.486804, 34.157184],
  湖北省: [112.410562, 31.209316],
  湖南省: [111.720664, 27.695864],
  河北省: [115.661434, 38.61384],
  黑龙江省: [128.047414, 47.356592],
  海南省: [109.733755, 19.180501],
  江苏省: [119.368489, 33.013797],
  江西省: [115.676082, 27.757258],
  吉林省: [126.262876, 43.678846],
  辽宁省: [122.753592, 41.6216],
  宁夏回族自治区: [106.155481, 37.321323],
  内蒙古自治区: [114.415868, 43.468238],
  青海省: [101.783997, 36.6091],
  上海: [121.472644, 31.231706],
  四川省: [104.078003, 30.650499],
  陕西省: [109.503789, 35.860026],
  山西省: [112.515496, 37.866566],
  山东省: [118.527663, 36.09929],
  天津: [117.190182, 39.125596],
  西藏自治区: [91.1343, 29.652399],
  新疆维吾尔自治区: [87.603798, 43.791199],
  云南省: [101.592952, 24.864213],
  重庆: [106.504962, 29.533155],
  浙江省: [119.957202, 29.159494],
  台湾: [121.509062, 25.044332],
};

export default coords;
