<template>
  <div class="chart-1">
    <div class="common-title title"><span>就业者年龄分布</span></div>
    <div class="common-content content">
      <div id="chart1"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import NP from 'number-precision';

export default {
  name: 'Chart1',
  props: ['age'],
  data() {
    return {
      myChart: undefined,
    };
  },
  watch: {
    age: function (n) {
      if (n.length) {
        let total = NP.plus(n[0], n[1], n[2], n[3], n[4], n[5]);
        this.setData(n, total);
      }
    },
  },
  methods: {
    setData(arr, total) {
      let option = {
        color: [
          '#6dc089',
          '#468fec',
          '#a2becd',
          '#e69c5a',
          '#b59ecc',
          '#654dd5',
        ],
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'item',
          show: false,
        },
        legend: {
          bottom: '10%',
          left: 'center',
          selectedMode: false,
          itemWidth: 10,
          itemHeight: 10,
          icon: 'rect',
          padding: [0, 50],
        },
        series: [
          {
            name: 'Age',
            type: 'pie',
            width: '378px',
            height: '360px',
            radius: ['40%', '70%'],
            center: ['50%', '45%'],
            startAngle: 180,
            label: {
              show: true,
              formatter(param) {
                return param.percent * 2 + '%';
              },
              position: 'inside',
              color: '#fff',
            },
            data: [
              { value: arr[0], name: '18-24岁' },
              { value: arr[1], name: '24-30岁' },
              { value: arr[2], name: '30-35岁' },
              { value: arr[3], name: '35-45岁' },
              { value: arr[4], name: '45-55岁' },
              { value: arr[5], name: '55岁以上' },
              {
                value: total,
                itemStyle: {
                  color: 'none',
                  decal: {
                    symbol: 'none',
                  },
                },
                label: {
                  show: false,
                },
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('chart1'), 'dark');
  },
};
</script>

<style lang="scss">
.chart-1 {
  width: 378px;
  margin-bottom: 26px;

  .title {
    span {
      &::after {
        width: 156px;
      }
    }
  }

  .content div {
    width: 378px;
    height: 266px;
  }
}
</style>
