<template>
  <div class="login-wrapper">
    <div class="header">
      <h1>久来米</h1>
      <span><b>中</b>简体中文</span>
    </div>

    <div class="outter">
      <div class="form-wrapper">
        <div class="pic"></div>
        <div class="login">
          <div class="title">账号登录</div>
          <div class="form">
            <input
              v-model="form.accountName"
              type="text"
              placeholder="请输入账号"
              autocomplete="off"
            />
            <input
              v-model="form.accountPwd"
              type="password"
              placeholder="请输入密码"
              autocomplete="off"
            />
          </div>
          <div v-if="error" class="error">{{ error }}</div>
          <div>
            <button :disabled="disabled" @click="handleLogin">登录</button>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">Copyright © 2023 ZANHUA All Rights Reserved</div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        accountName: '',
        accountPwd: '',
      },
      error: '',
    };
  },
  computed: {
    disabled() {
      return !this.form.accountName || !this.form.accountPwd;
    },
  },
  methods: {
    async handleLogin() {
      const data = await this.$axios.post('screen/wechat/screen/login', {
        ...this.form,
      });
      if (data.success) {
        sessionStorage.setItem('token', data.data);
        this.$router.push('home');
      } else {
        this.error = data.rtnMessage;
      }
    },
  },
  created() {
    document.querySelector('body').className = 'login-page';
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  min-height: 100vh;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 60px 70px 96px;
    color: #fff;

    h1 {
      margin: 0;
      font-size: 30px;
      letter-spacing: 0.03px;
    }

    span {
      margin-top: 12px;
      height: 22px;
      line-height: 22px;

      b {
        display: inline-block;
        width: 22px;
        height: 22px;
        background: #ffffff;
        margin-right: 10px;
        color: #f8742f;
        border-radius: 2px;
        text-align: center;
        vertical-align: top;
      }
    }
  }
  .outter {
    position: absolute;
    top: 50%;
    width: 100%;
    min-height: 520px;
    background: url('../assets/login_bg.png') no-repeat 12% 0 / 731px 520px;
    transform: translateY(-50%);

    .form-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 1000px;
      margin: 0 auto;

      .pic {
        width: 487px;
        height: 323px;
        background: url('../assets/login_pic.png') no-repeat 0 0 / contain;
        margin-top: 50px;
      }

      .login {
        position: relative;
        width: 397px;
        min-height: 411px;
        background: #fff;
        padding: 0 25px;
        border-radius: 4px;
        box-sizing: border-box;

        .title {
          padding: 36px 0 20px;
          border-bottom: 0.5px solid #c5cad5;
          color: #111a34;
          font-size: 16px;
          text-indent: 10px;
        }

        .form {
          padding: 36px 9px;
        }

        input {
          width: 100%;
          height: 48px;
          background: #f7f8fb;
          margin: 0 0 24px;
          padding: 0 24px;
          border: none;
          border-radius: 6px;
          color: #858b9c !important;
          font-size: 14px;
          font-weight: 400;
          box-sizing: border-box;
          box-shadow: inset 0 0 0 1000px #f7f8fb;
        }

        button {
          display: block;
          width: 100%;
          height: 44px;
          line-height: 44px;
          background: #f77d3e;
          margin: 0 0 10px;
          padding: 0;
          border: none;
          border-radius: 48px;
          color: #fff;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
        }

        .error {
          margin: 0 0 10px;
          color: crimson;
          font-size: 12px;
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    color: rgba(#fff, 0.6);
    font-size: 11px;
    text-align: center;

    a {
      color: #fff;
    }
  }
}
</style>
