import Vue from 'vue';
import Router from 'vue-router';

import Home from '../pages/Home.vue';
import Login from '../pages/Login.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
  ],
});

export default router;
