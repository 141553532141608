<template>
  <div class="chart-4">
    <div class="common-title title"><span>近半年服务行业产值TOP3</span></div>
    <div class="common-content content">
      <div id="chart4"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'Chart4',
  props: ['month', 'amountTop3'],
  data() {
    return {
      colors: {
        0: {
          start: 'rgba(41, 241, 250, 1)',
          end: 'rgba(41,241,250,0)',
        },
        1: {
          start: 'rgba(27, 126, 242, 1)',
          end: 'rgba(27, 126, 242, 0)',
        },
        2: {
          start: 'rgba(242, 153, 74, 1)',
          end: 'rgba(242, 153, 74, 0)',
        },
      },
    };
  },
  watch: {
    amountTop3: function (n) {
      if (n.length) {
        this.setData(n);
      }
    },
  },
  methods: {
    setData(data) {
      let arr = [];
      for (const [index, item] of data.entries()) {
        arr.push({
          ...item,
          type: 'line',
          smooth: true,
          seriesLayoutBy: 'row',
          emphasis: { focus: 'series' },
          symbol: 'none',
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: this.colors[index].start,
              },
              {
                offset: 1,
                color: this.colors[index].end,
              },
            ]),
          },
        });
      }
      let option = {
        color: ['#29F1FA', '#1B7EF2', '#F27C1B'],
        backgroundColor: 'transparent',
        legend: {
          top: '10%',
          left: 'center',
          selectedMode: false,
          itemWidth: 19,
          itemHeight: 3,
          icon: 'rect',
          padding: [0, 50],
        },
        xAxis: {
          type: 'category',
          data: this.month,
          splitLine: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(27, 126, 242, 0.5)',
            },
          },
          boundaryGap: false,
        },
        yAxis: { splitLine: { show: false } },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        series: arr,
      };
      this.myChart.setOption(option);
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('chart4'), 'dark');
  },
};
</script>

<style lang="scss">
.chart-4 {
  width: 378px;
  margin-bottom: 26px;

  .title {
    span {
      &::after {
        width: 57px;
      }
    }
  }

  .content div {
    width: 378px;
    height: 360px;
  }
}
</style>
