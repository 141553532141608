<template>
  <v-scale-screen width="1920" height="1157">
    <div class="wrapper">
      <!-- <video class="video" autoplay muted loop>
        <source src="../assets/bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> -->
      <header>
        <div class="light"></div>
      </header>
      <div class="container">
        <Top :top="info.top" />
        <div class="left">
          <Box1 :gender="info.gender" />
          <Chart1 :age="info.age" />
          <Chart2 :province="info.province" />
        </div>
        <div class="right">
          <Chart3 :month="info.month" :countTop3="info.countTop3" />
          <Chart4 :month="info.month" :amountTop3="info.amountTop3" />
          <div class="attribution"></div>
        </div>
        <Map :provinceStat="info.provinceStat" />
        <Slider :provinceStat="info.provinceStat" />
      </div>
      <footer>
        <div class="l rectangle"></div>
        <div class="l rectangle-reverse"></div>
        <div class="r rectangle"></div>
        <div class="r rectangle-reverse"></div>
      </footer>
    </div>
  </v-scale-screen>
</template>

<script>
import Vue from 'vue';
import dayjs from 'dayjs';
import NP from 'number-precision';
import VScaleScreen from 'v-scale-screen';
import Top from '../components/Top.vue';
import Box1 from '../components/Box1.vue';
import Chart1 from '../components/Chart1.vue';
import Chart2 from '../components/Chart2.vue';
import Chart3 from '../components/Chart3.vue';
import Chart4 from '../components/Chart4.vue';
import Map from '../components/Map.vue';
import Slider from '../components/Slider.vue';

Vue.use(VScaleScreen);

export default {
  name: 'Home',
  components: {
    VScaleScreen,
    Top,
    Box1,
    Chart1,
    Chart2,
    Chart3,
    Chart4,
    Map,
    Slider,
  },
  data() {
    return {
      token: '',
      info: {
        top: {
          taxCount: 0,
          entCount: 0,
          userCount: 0,
          sumMoney: 0,
        },
        gender: {
          男: 0,
          女: 0,
        },
        age: [],
        province: [],
        month: [],
        countTop3: [],
        amountTop3: {},
        provinceStat: {},
      },
      timer: null,
    };
  },
  created() {
    this.token = sessionStorage.getItem('token');
    this.getData();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
  methods: {
    formatAmountWan(value) {
      return NP.round(NP.divide(Number(value), 10000), 0);
    },
    formatAmountYi(value) {
      return NP.round(NP.divide(Number(value), 100000000), 0);
    },
    getData() {
      this.timer && clearInterval(this.timer);
      new Promise(async (resolve, reject) => {
        const data = await this.$axios.post('screen/wechat/screen/getScreen', {
          token: this.token,
        });
        if (data.success) {
          console.log('getScreen');
          const { taxCount, entCount, userCount, sumMoney } = data.data;
          this.info = {
            ...data.data,
            top: {
              taxCount,
              entCount: Number(entCount),
              userCount: this.formatAmountWan(userCount),
              sumMoney: this.formatAmountYi(sumMoney),
            },
          };
          resolve();
        } else {
          reject();
        }
      }).finally(() => {
        this.intervalFixedPointTaskFn(
          {
            timeFixedArr: ['12:00:00', '14:00:00', '16:00:00'],
            delayTime: 1000,
          },
          this.getData
        );
      });
      // setTimeout(() => {
      //   this.info = {
      //     ...this.info,
      //     provinceStat: {
      //       江西省: { userCount: 100, money: 100, entCount: 100 },
      //       河南省: { userCount: 200, money: 200, entCount: 200 },
      //     },
      //     top: { taxCount:1000, entCount:1000, userCount:1000, sumMoney:1000 },
      //   };
      // }, 5000);
    },
    intervalFixedPointTaskFn({ timeFixedArr, delayTime }, tickerFn) {
      let executed = true;
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        // console.log('timer...');
        let currentTime = new Date().getTime();
        let currentYMD = dayjs(currentTime).format('YYYY-MM-DD');
        let refresh = false;
        let count = 0;
        for (let i = 0; i < timeFixedArr.length; i++) {
          let item = timeFixedArr[i];
          let setTime = new Date(`${currentYMD} ${item}`).getTime();
          if (currentTime >= setTime && currentTime <= setTime + delayTime) {
            if (!executed) {
              refresh = true;
            }
            break;
          } else {
            count++;
          }
        }

        if (count === timeFixedArr.length) {
          executed = false;
        }

        if (refresh) {
          executed = true;
          console.log(
            `${dayjs(currentTime).format(
              'YYYY-MM-DD HH:mm:ss'
            )} 执行定时任务....`
          );
          tickerFn && tickerFn();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  width: 1920px;
  background: #000001;
  margin: 0 auto;

  header {
    position: relative;
    height: 121px;
    background: url('../assets/header.png') no-repeat center top / contain;

    .light {
      position: absolute;
      top: 50px;
      left: 40%;
      width: 397px;
      height: 164px;
      background: url('../assets/header_light.png') no-repeat center top /
        contain;
      margin-left: -199px;
      animation-name: light;
      animation-duration: 8s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes light {
    25% {
      transform: scaleX(1.5);
    }
    50% {
      left: 60%;
      transform: scaleX(1);
    }
    75% {
      transform: scaleX(1.5);
    }
  }

  footer {
    position: relative;
    height: 55px;
    background: url('../assets/footer.png') no-repeat center top / contain;

    div {
      position: absolute;
      top: 13px;
      width: 16px;
      height: 2px;
      background: #e69c5a;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    .rectangle {
      transform: skewX(-50deg);
      animation-name: rectangle;

      &.l {
        left: 27%;
      }

      &.r {
        right: 27%;
        transform: skewX(50deg);
      }
    }

    .rectangle-reverse {
      opacity: 0.5;
      transform: skewX(-50deg);
      animation-name: rectangleReverse;

      &.l {
        left: 28.3%;
      }

      &.r {
        right: 28.3%;
        transform: skewX(50deg);
      }
    }
  }

  @keyframes rectangle {
    50% {
      opacity: 0.5;
    }
  }
  @keyframes rectangleReverse {
    50% {
      opacity: 1;
    }
  }
}

// .video {
//   position: absolute;
//   top: 0;
//   left: 0;
//   // width: 1920px;
//   height: 100%;
//   opacity: 0.4;
// }

.container {
  position: relative;
  min-height: 972px;
  color: rgba(#fff, 0.87);
  font-size: 22px;

  .left,
  .right {
    position: absolute;
    top: 0;
    z-index: 9999;
  }

  .left {
    left: 40px;
  }

  .right {
    right: 40px;
  }

  .attribution {
    height: 87px;
    background: url('../assets/attribution.png') no-repeat 0 0 / contain;
  }
}
</style>
