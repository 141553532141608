<template>
  <div class="slider">
    <div class="title">地图各个省份数据</div>
    <swiper class="swiper" :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(item, index) in datas" :key="index">
        <dl>
          <dt>地区：</dt>
          <dd>{{ item.name }}</dd>
        </dl>
        <dl>
          <dt>服务企业：</dt>
          <dd>{{ item.entCount }}家</dd>
        </dl>
        <dl>
          <dt>就业者：</dt>
          <dd>{{ item.userCount }}个</dd>
        </dl>
        <dl>
          <dt>累计产值：</dt>
          <dd>{{ item.money }}万</dd>
        </dl>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import NP from 'number-precision';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'Slider',
  props: ['provinceStat'],
  data() {
    return {
      swiperOption: {
        loop: true,
        effect: 'fade',
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      datas: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  watch: {
    provinceStat: function (n) {
      if (Object.keys(n).length) {
        this.setData(n);
      }
    },
  },
  methods: {
    convertData(obj) {
      let res = [];
      Object.keys(obj).map((item) => {
        res.push({
          name: item,
          money: this.formatAmount(obj[item].money),
          userCount: this.formatCount(obj[item].userCount),
          entCount: this.formatCount(obj[item].entCount),
        });
      });
      return res;
    },
    async setData(arr) {
      let data = await this.convertData(arr);
      // console.log(JSON.stringify(data));
      this.datas = [...data];
    },
    formatAmount(value) {
      return NP.round(NP.divide(value, 10000), 0);
    },
    formatCount(value) {
      return Number(value).toLocaleString('zh', {
        minimumFractionDigits: 0,
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.slider {
  position: absolute;
  bottom: 60px;
  left: 510px;
  width: 264px;
  height: 172px;
  background: #000001 url('../assets/slider.png') no-repeat 0 0 / contain;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;

  .title {
    height: 28px;
    line-height: 28px;
    color: #29f1fa;
    font-size: 18px;
    font-weight: 500;
  }
}

.swiper {
  height: 96px;

  .swiper-slide {
    background-color: #000001;
    color: rgba(#fff, 0.8);
    font-size: 14px;

    dl {
      display: flex;
      margin: 0;

      dt {
        width: 5em;
      }

      dd {
        margin: 0;
      }
    }
  }
}
</style>
